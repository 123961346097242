<template>
  <div class="home page-wrapper">
    <div class="container-xl">
      <!-- Page title -->
      <div class="page-header d-print-none">
        <div class="row align-items-center">
          <div class="col">
            <!-- Page pre-title -->
            <h2 class="page-title">Reset Password</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="container-xl" v-if="loaded">
        <div
          class="row row-deck row-cards"
          v-if="!displayBadToken && !displayExpiredError && !displayEmailSent"
        >
          <div class="card card-md" v-if="!displayResetError">
            <div class="card-body">
              <div class="row">
                <div class="col-5 mb-3">
                  <p class="h2">Reset Password</p>
                  <div>
                    <label class="form-label mt-3">Password:</label>
                    <input
                      :class="[
                        'form-control d-inline leaveroomforlabel',
                        this.formErrors.password ? 'is-invalid' : '',
                      ]"
                      type="password"
                      id="validatepassword"
                      ref="password"
                      autocomplete="off"
                      v-model="password"
                      required
                      @blur="validate('password')"
                    />
                    <div class="invalid-feedback">
                      <small>{{ this.passworderrormessage }}</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-5 p-right">
                  <label class="form-label mt-3">Confirm Password:</label>
                  <input
                    :class="[
                      'form-control d-inline leaveroomforlabel',
                      this.formErrors.confirmpassword ? 'is-invalid' : '',
                    ]"
                    type="password"
                    id="validateconfirmpassword"
                    ref="confirmpassword"
                    autocomplete="off"
                    v-model="confirmpassword"
                    required
                    @blur="validate('confirmpassword')"
                  />
                  <div class="invalid-feedback">
                    <small>{{ this.confirmpassworderrormessage }}</small>
                  </div>
                </div>
              </div>

              <div class="mt-4">
                <div class="d-inline" style="margin-right: 15px">
                  <button
                    @click.prevent="savePassword"
                    class="btn btn-block btn-primary"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row row-deck row-cards" v-else-if="displayExpiredError">
          <div class="card card-md">
            <div class="card-body">
              <div class="row">
                <div class="col-12 mb-3">
                  <p class="h2">Your Password Link Expired</p>
                  <p>
                    We'll need to resend your authentication email. Remember
                    this link is only valid for 24 hours.
                  </p>
                  <div class="col-5 p-right">
                    <label class="form-label mt-1 font-weight-bold"
                      >Email Address:</label
                    >
                    <input
                      :class="[
                        'form-control d-inline leaveroomforlabel',
                        this.formErrors.emailaddress ? 'is-invalid' : '',
                      ]"
                      id="validateEmailAddress"
                      ref="emailaddress"
                      v-model="emailaddress"
                      required
                      @blur="validate('emailaddress')"
                    />
                    <div class="invalid-feedback">
                      <small>{{ this.emailaddresserrormessage }}</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-2">
                <div class="d-inline" style="margin-right: 15px">
                  <button
                    @click.prevent="sendResetEmail"
                    class="btn btn-block btn-primary"
                  >
                    Send Link
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center" v-else-if="displayBadToken">
          <span class="bg-red text-white avatar me-2">
            <!-- Download SVG icon from http://tabler-icons.io/i/currency-dollar -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-circle-x"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <circle cx="12" cy="12" r="9"></circle>
              <path d="M10 10l4 4m0 -4l-4 4"></path>
            </svg>
          </span>
          <h3 class="mb-0">
            Token invalid.
            <router-link to="/login"> Sign in &raquo; </router-link>
          </h3>
        </div>

        <div
          class="d-flex justify-content-center"
          v-else-if="displayResetError"
        >
          <div class="card card-md col-6">
            <div class="card-status-top bg-danger"></div>
            <div class="card-body text-center">
              <span class="h4">
                We are unable to update your account password online. Please
                contact Customer Service at 1.866.627.2467 or email
                <a href="mailto:advertisersupport@mediabrains.com"
                  >advertisersupport@mediabrains.com</a
                >.
              </span>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center" v-else-if="displayEmailSent">
          <div class="card card-md col-6">
            <div class="card-status-top bg-success"></div>
            <div class="card-body text-center">
              <span class="h4">
                {{ this.resentmsg }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="container-xl" v-else>
        <div class="row">
          <div class="col">
            <h3>Loading reset password...</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.leaveroomforlabel {
  width: calc(100% - 208px) !important;
}
</style>

<script>
import TrackingService from "../services/TrackingService";

export default {
  name: "ResetPassword",
  components: {},
  data: () => {
    return {
      loaded: false,
      postSuccess: false,
      postError: false,
      existingResetToken: [],
      emailaddress: "",
      password: "",
      confirmpassword: "",
      resettoken: "",
      resettokenbytestring: "",
      expired: false,
      reseterror: false,
      emailsent: false,
      badtoken: false,
      companyid: 0,
      contactid: 0,
      sudotoken: "",
      emailaddresserrormessage: "Please enter an emailaddress",
      passworderrormessage: "Please enter a password",
      confirmpassworderrormessage: "Confirm password does not match password",
      resentmsg: "",
      formErrors: {},
    };
  },
  methods: {
    validate(item) {
      if (item === "emailaddress") {
        if (this.emailaddress == "" || this.emailaddress == null) {
          this.$set(this.formErrors, "emailaddress", true);
        } else {
          this.$set(this.formErrors, "emailaddress", false);
        }
      }
      if (item === "all" || item === "password") {
        if (this.password == "" || this.password == null) {
          this.$set(this.formErrors, "password", true);
        } else {
          this.$set(this.formErrors, "password", false);
        }
      }
      if (item === "all" || item === "confirmpassword") {
        if (
          this.confirmpassword == "" ||
          this.confirmpassword == null ||
          this.confirmpassword != this.password
        ) {
          this.$set(this.formErrors, "confirmpassword", true);
        } else {
          this.$set(this.formErrors, "confirmpassword", false);
        }
      }
    },
    async getUserInfoByResetToken() {
      this.resettoken = this.$route.query.t;
      this.resettokenbytestring = this.$route.query.e;

      // check that ResetToken matches ResetTokenByteString
      if (window.btoa(this.resettoken) != this.resettokenbytestring) {
        // 404 does not exist in MAC?
        this.loaded = true;
        this.badtoken = true;
        return;
      }

      await this.$auth.getuserbyresettoken(this.resettoken);

      let resetTokenDTO = this.$auth.resetinfo;

      // set properties
      this.expired = resetTokenDTO.expired;
      this.userid = resetTokenDTO.userID;
      this.companyid = resetTokenDTO.companyID;
      this.contactid = resetTokenDTO.contactID;
      this.sudotoken = resetTokenDTO.sudoToken;
      this.emailaddress = resetTokenDTO.emailAddress;

      //save resetTokenDTO
      this.existingResetToken = resetTokenDTO;

      // mark as loaded
      this.loaded = true;
    },
    async savePassword() {
      this.validate("all");

      let formHasErrors = Object.values(this.formErrors).some((i) => i);

      // Check for form errors
      if (formHasErrors) {
        return;
      }

      // mark as reload
      this.reload = true;

      if (!formHasErrors) {
        try {
          let postResult = await this.$auth.savepassword(
            this.userid,
            this.password
          );

          if (postResult) {
            this.postSuccess = true;
            this.$router.push(
              "/login?email=" + encodeURIComponent(this.emailaddress)
            );
            TrackingService.track("successfully reset password", {
              companyId: this.companyid,
              contactId: this.contactid,
            });
          } else {
            this.postSuccess = false;
            this.postError = true;
            TrackingService.track("failed to reset password", {
              companyId: this.companyid,
              contactId: this.contactid,
            });
          }
        } catch (e) {
          this.postSuccess = false;
          this.postError = true;
          TrackingService.track("failed to reset password", {
            companyId: this.companyid,
            contactId: this.contactid,
          });
        }
      }

      // mark reload as complete
      this.reload = false;
    },
    async sendResetEmail() {
      this.validate("emailaddress");

      let formHasErrors = Object.values(this.formErrors).some((i) => i);

      // Check for form errors
      if (formHasErrors) {
        return;
      }

      // mark as reload
      this.reload = true;

      if (!formHasErrors) {
        try {
          let postResult = await this.$auth.resendresetemail(
            this.contactid,
            this.companyid
          );

          if (postResult) {
            this.postSuccess = true;
            this.emailsent = true;
            this.expired = false;
            this.resentmsg = "Email sent to " + this.emailaddress;
          } else {
            this.resentmsg = "We are unable to send your authentication email.";
            this.postSuccess = false;
            this.postError = true;
          }
        } catch (e) {
          console.log(e);
          this.postSuccess = false;
          this.postError = true;
        }
      }

      // mark reload as complete
      this.reload = false;
    },
  },
  computed: {
    displayResetError() {
      return this.postError;
    },
    displayExpiredError() {
      return this.expired;
    },
    displayEmailSent() {
      return this.emailsent;
    },
    displayBadToken() {
      return this.badtoken;
    },
  },
  async mounted() {
    // load user from passed in reset token
    this.getUserInfoByResetToken();
    TrackingService.pageUnauthenticated(this.$router.name);
  },
};
</script>
